import Vue from 'vue';
import { make } from 'vuex-pathify';

import api from '@/services/api';
import { arrayToObject } from '@/assets/js/utility';

const state = {
  isFromProfile: false,
  isScenarioAdded: false,
  models: {},
  modelsScenarios: {},
  scenariosElements: {},
  scenariosElementsByName: {},
  scenariosNotFoundedElementsByName: {},
  currentTimestepIndex: 0,
  sewageOutpouringProfiles: [],
  sewageVolumeProfiles: [],
};

const mutations = {
  ...make.mutations(state),
  ADD_MODELS_SCENARIO(state, payload) {
    const { value, id } = payload;
    Vue.set(state.modelsScenarios, id, value);
  },
  ADD_SCENARIOS_ELEMENT_DATA(state, payload) {
    const { scenarioId, elementId, type, netType, value } = payload;
    // 🤮
    if (!state.scenariosElements[netType]) {
      Vue.set(state.scenariosElements, netType, {});
    }
    if (!state.scenariosElements[netType][scenarioId]) {
      Vue.set(state.scenariosElements[netType], scenarioId, {});
    }
    if (!state.scenariosElements[netType][scenarioId][type]) {
      Vue.set(state.scenariosElements[netType][scenarioId], type, {});
    }
    Vue.set(state.scenariosElements[netType][scenarioId][type], elementId, value);
  },
  ADD_SCENARIOS_ELEMENT_DATA_BY_NAME(state, payload) {
    const { scenarioId, elementLabel, type, netType, value } = payload;
    if (!state.scenariosElementsByName[netType]) {
      Vue.set(state.scenariosElementsByName, netType, {});
    }
    if (!state.scenariosElementsByName[netType][scenarioId]) {
      Vue.set(state.scenariosElementsByName[netType], scenarioId, {});
    }
    if (!state.scenariosElementsByName[netType][scenarioId][type]) {
      Vue.set(state.scenariosElementsByName[netType][scenarioId], type, {});
    }
    Vue.set(state.scenariosElementsByName[netType][scenarioId][type], elementLabel, value);
  },
  ADD_SCENARIOS_NOT_FOUNDED_ELEMENT(state, data) {
    const { scenarioId, elementLabel, type, netType } = data;
    if (!state.scenariosNotFoundedElementsByName[netType]) {
      Vue.set(state.scenariosNotFoundedElementsByName, netType, {});
    }
    if (!state.scenariosNotFoundedElementsByName[netType][scenarioId]) {
      Vue.set(state.scenariosNotFoundedElementsByName[netType], scenarioId, {});
    }
    if (!state.scenariosNotFoundedElementsByName[netType][scenarioId][type]) {
      Vue.set(state.scenariosNotFoundedElementsByName[netType][scenarioId], type, [elementLabel]);
    } else if (!state.scenariosNotFoundedElementsByName[netType][scenarioId][type].includes(elementLabel)) {
      Vue.set(state.scenariosNotFoundedElementsByName[netType][scenarioId], type, [
        ...state.scenariosNotFoundedElementsByName[netType][scenarioId],
        elementLabel,
      ]);
    }
  },
};

const actions = {
  async deleteModel(store, { id, netType }) {
    await api.delete(`${netType}/modeling_results/models/${id}`);
  },
  async getModels({ commit, state }, netType) {
    const r = await api.get(`${netType}/modeling_results/models`);
    commit('SET_MODELS', { ...(state.models || {}), [netType]: arrayToObject(r.data.data) });
    const scenarios = r.data.data.map(model => model.scenarios).flat();
    commit('SET_MODELS_SCENARIOS', { ...(state.modelsScenarios || {}), [netType]: arrayToObject(scenarios) });
  },
  async getModelScenarios({ commit }, { id, netType }) {
    const r = await api.get(`${netType}/modeling_results/models/${id}/scenarios`);
    commit('ADD_MODELS_SCENARIO', { id, value: r.data.data });
  },
  async getNetElementValues({ commit }, payload) {
    const { scenarioId, elementId, type, netType } = payload;
    const r = await api.get(`${netType}/modeling_results/scenarios/${scenarioId}/${type}/${elementId}/values`);
    commit(`ADD_SCENARIOS_ELEMENT_DATA`, { ...payload, value: r.data.data });
    commit(`ADD_SCENARIOS_ELEMENT_DATA_BY_NAME`, { ...payload, elementLabel: r.data.data.label, value: r.data.data });
    return r.data.data;
  },
  async getNetElementValuesByName({ commit }, payload) {
    const { scenarioId, elementLabel, type, netType } = payload;
    try {
      const r = await api.get(
        `${netType}/modeling_results/scenarios/${scenarioId}/${type}/${elementLabel}/values_by_name`,
        { skipDefaultErrorHandler: true }
      );
      commit(`ADD_SCENARIOS_ELEMENT_DATA_BY_NAME`, { ...payload, value: r.data.data });
      return r.data.data;
    } catch (error) {
      // Element with the given id was not found in the scenario,
      // so it is not added to the graph and table
      commit(`ADD_SCENARIOS_NOT_FOUNDED_ELEMENT`, payload);
      return error;
    }
  },
  async getModelingResultsTile(store, { envelope, scenarioId, type, netType }) {
    const r = await api.post(
      `/${netType}/modeling_results/scenarios/${scenarioId}/${type}/mvt`,
      { envelope },
      {
        responseType: 'arraybuffer',
        timeout: 0,
        skipDefaultErrorHandler: true,
      }
    );
    return r;
  },
  async getIntersectingLinks(store, { type, scenarioId, body }) {
    return await api.post(`sewer/modeling_results/scenarios/${scenarioId}/${type}`, body);
  },
  async getProfile(store, { date, pipes, scenarioId, index }) {
    const r = await api.post(
      `/sewer/modeling_results/profile/${scenarioId}`,
      {
        pipes,
        date,
        time_index: index,
      },
      {
        responseType: 'arraybuffer',
        timeout: 0,
      }
    );
    return r;
  },
  async getSewerDataProfile(store, payload) {
    return await api.post('sewer_analysis/profile', payload, { responseType: 'arraybuffer' });
  },
  async getWaterDataProfile(store, payload) {
    return await api.post('water_network_profile', payload, { responseType: 'arraybuffer' });
  },
  async getInvertedGradientNodes(store, payload) {
    return await api.post(`sewer_analysis/inverted-gradient`, payload);
  },
  async getDownstreamNodes(store, payload) {
    const { oid, body } = payload;
    return await api.post(`sewer_analysis/downstream/${oid}`, body);
  },
  async getUpstreamNodes(store, payload) {
    const { oid, body } = payload;
    return await api.post(`sewer_analysis/upstream/${oid}`, body);
  },
  async exportToEkart(store, scenarioId) {
    return await api.post(`water/modeling_results/${scenarioId}/export/ekart`);
  },
  async upstreamSewageVolumeAnalysis(store, payload) {
    const r = await api.post(`/sewer_analysis/upstream/sewage_volume`, payload);
    return r.data.data;
  },
  async upstreamSewageOutpouringAnalysis(store, payload) {
    const r = await api.post(`/sewer_analysis/upstream/sewage_outpouring`, payload);
    return r.data.data;
  },
  async getSewageVolumeProfiles({ commit }) {
    const r = await api.get(`/sewer_analysis/upstream/sewage_volume/profile`);
    commit(`SET_SEWAGE_VOLUME_PROFILES`, r.data.data);
  },
  async saveSewageVolumeProfile({ dispatch }, payload) {
    const r = await api.post(`/sewer_analysis/upstream/sewage_volume/profile`, payload);
    await dispatch('getSewageVolumeProfiles');
    return r.data.data;
  },
  async deleteSewageVolumeProfile({ dispatch }, profileId) {
    await api.delete(`/sewer_analysis/upstream/sewage_volume/profile/${profileId}`);
    await dispatch('getSewageVolumeProfiles');
  },
  async editSewageVolumeProfile({ dispatch }, data) {
    const { profileId, payload } = data;
    await api.put(`/sewer_analysis/upstream/sewage_volume/profile/${profileId}`, payload);
    await dispatch('getSewageVolumeProfiles');
  },
  async getSewageOutpouringProfiles({ commit }) {
    const r = await api.get(`/sewer_analysis/upstream/sewage_outpouring/profile`);
    commit(`SET_SEWAGE_OUTPOURING_PROFILES`, r.data.data);
  },
  async saveSewageOutpouringProfile({ dispatch }, payload) {
    const r = await api.post(`/sewer_analysis/upstream/sewage_outpouring/profile`, payload);
    await dispatch('getSewageOutpouringProfiles');
    return r.data.data;
  },
  async deleteSewageOutpouringProfile({ dispatch }, profileId) {
    await api.delete(`/sewer_analysis/upstream/sewage_outpouring/profile/${profileId}`);
    await dispatch('getSewageOutpouringProfiles');
  },
  async editSewageOutpouringProfile({ dispatch }, data) {
    const { profileId, payload } = data;
    await api.put(`/sewer_analysis/upstream/sewage_outpouring/profile/${profileId}`, payload);
    await dispatch('getSewageOutpouringProfiles');
  },
  async exportScenarioToDataSource({ dispatch }, { scenarioId, disableLayersRefresh = false } = {}) {
    const r = await api.post(`water/modeling_results/${scenarioId}/export/datasource`);
    if (!disableLayersRefresh) {
      await Promise.all([
        dispatch('layers/getLayersSchema', {}, { root: true }),
        dispatch('layers/getLayersMetadata', { with_attributes_schema: true }, { root: true }),
        dispatch('layers/getLayers', { scope: 'core' }, { root: true }),
        dispatch('users/getCurrentUser', {}, { root: true }),
      ]);
    }
    return r.data.data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
