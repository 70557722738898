import api from '@/services/api';

import Vue from 'vue';

import { make } from 'vuex-pathify';

import { arrayToObject } from '@/assets/js/utility';

const state = {
  settings: undefined,
  settingsValues: {},
  mapping: undefined,
  additionalModules: {},
  hasMobileApp: {},
  hasQgis: false,
  mobileAppMetadata: {},
  allowedRelations: [],
  dictionaries: [],
  relationsDictionaries: [],
  modulesMapping: {},
  qgisMetadata: {},
  appKind: `${import.meta.env.VUE_APP_APP_KIND}`,
  backendVersion: '',
  // eslint-disable-next-line no-undef
  frontendVersion: `${_FRONT_VERSION_DATE_}`,
  geoserver: {},
  mobileRegistryDevices: {},
  publicTokens: [],
};

const mutations = {
  ...make.mutations(state),
  EDIT_SETTINGS_VALUE: (state, payload) => {
    const { key, value } = payload;
    const setting = state.settings.find(setting => setting.name === key);
    Vue.set(setting, 'value', value);
    Vue.set(state.settingsValues, key, value);
  },
  SET_MODULE_VALUE: (state, value) => {
    const { name, enabled } = value;
    Vue.set(state.additionalModules[name], 'enabled', enabled);
  },
  SET_MOBILE_APP_METADATA: (state, value) => {
    const { app_kind, metadata } = value;
    Vue.set(state.mobileAppMetadata, app_kind, metadata);
  },
  SET_HAS_MOBILE_APP: (state, value) => {
    const { app_kind, mobileApp } = value;
    Vue.set(state.hasMobileApp, app_kind, mobileApp);
  },
  SET_DICTIONARIES: (state, value) => {
    const dicts = value.reduce((total, current) => {
      return { ...total, [current.id]: current };
    }, {});
    state.dictionaries = { ...dicts };
  },
  DELETE_DICTIONARY: (state, value) => {
    Vue.delete(state.dictionaries, value);
  },
  SET_MOBILE_REGISTRY_DEVICE: (state, value) => {
    Vue.set(state.mobileRegistryDevices, value.id, value);
  },
};

const actions = {
  async getBackendVersion({ commit }) {
    const r = await api.get('current_version');
    const value = [...r.data.data.split(':', 1), '0.0.0'];
    console.log(`%cWersja back-endu: ${value[0]}, ${value[1]}`, 'font-size: 1.2em; font-weight: bold');
    commit('SET_BACKEND_VERSION', value[0]);
  },
  async checkHasImage(store, type) {
    const r = await api.get(`settings/${type}/custom`);
    return r.status === 200;
  },
  async deleteLogo(store, type) {
    const r = await api.delete(`settings/${type}`);
    return r.status === 204;
  },
  async checkHasFavicon() {
    const r = await api.get('favicon.ico/custom');
    return r.status === 200;
  },
  async deleteFavicon() {
    const r = await api.delete('favicon.ico');
    return r.status === 204;
  },
  async checkCustomLogo(store, filename) {
    const r = await api.get(`/settings/logo/custom/${filename}`);
    return r.status === 200;
  },
  async deleteCustomLogo(store, filename) {
    const r = await api.delete(`/settings/logo/${filename}`);
    return r.status === 204;
  },
  async editSingleSetting({ commit }, payload) {
    const { key, body } = payload;
    await api.put(`settings/${key}`, body);
    commit('EDIT_SETTINGS_VALUE', { key, value: body.value });
  },
  async getSingleSetting({ commit }, key) {
    const r = await api.get(`settings/${key}`);
    commit('EDIT_SETTINGS_VALUE', { key, value: r.data.data });
  },
  async getSettings({ commit }) {
    const r = await api.get('settings');
    const settingsValues = r.data.data.reduce((t, c) => {
      return { ...t, [c.name]: c.value };
    }, {});
    commit('SET_SETTINGS', r.data.data);
    commit('SET_SETTINGS_VALUES', settingsValues);
  },
  async getPublicSettings({ commit }) {
    const r = await api.get('settings/public');
    const settingsValues = r.data.data.reduce((t, c) => {
      return { ...t, [c.name]: c.value };
    }, {});
    commit('SET_SETTINGS', r.data.data);
    commit('SET_SETTINGS_VALUES', settingsValues);
    return r;
  },
  async getMapping({ commit }) {
    const r = await api.get('app_data/mapping');
    commit('SET_MAPPING', r.data.data);
    return r;
  },
  async getModulesMapping({ commit }) {
    const r = await api.get('modules_mapping');
    commit('SET_MODULES_MAPPING', r.data.data);
  },
  async deleteAppData(store, dataSource) {
    await api.delete(`app_data/${dataSource}/features`);
  },
  async sendMail(store, payload) {
    await api.post('email/send', payload);
  },
  async getAdditionalModules({ commit }, isPublic) {
    const r = await api.get(`license_manager/modules${isPublic ? '/public' : ''}`);
    const modules = r.data.data.reduce((t, c) => {
      return { ...t, [c.name]: c };
    }, {});
    commit('SET_ADDITIONAL_MODULES', modules);
  },
  async setAdditionalModuleValue({ dispatch }, payload) {
    const { name, enabled } = payload;
    await api.post(`license_manager/modules/${name}/${enabled ? 'enable' : 'disable'}`);
    await dispatch('getSettings');
    await dispatch('users/getCurrentUser', {}, { root: true });
  },
  async changeAdditionalModuleName({ dispatch }, payload) {
    const { name, data } = payload;
    await api.put(`license_manager/modules/${name}`, data);
    await dispatch('getAdditionalModules');
  },
  async getAllowedRelations({ commit }) {
    const r = await api.get('dataio/data_sources/relation');
    commit('SET_ALLOWED_RELATIONS', r.data.data);
  },
  async getDictionaries({ commit }) {
    const r = await api.get('dicts');
    commit('SET_DICTIONARIES', r.data.data);
  },
  async getSpecificDictionary(store, id) {
    const r = await api.get(`dicts/${id}`);
    return r.data.data;
  },
  async postDictionary({ dispatch }, formData) {
    const r = await api.post('dicts', formData, {
      noWrap: true,
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    });
    await dispatch('getDictionaries');
    return r.data.data;
  },
  async putDictionary({ dispatch }, data) {
    const { formData, id } = data;
    await api.put(`dicts/${id}`, formData, {
      noWrap: true,
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    });
    await dispatch('getDictionaries');
  },
  async deleteDictionary({ commit }, id) {
    await api.delete(`dicts/${id}`);
    commit('DELETE_DICTIONARY', id);
  },
  async getRelationsDictionaries({ commit }) {
    const r = await api.get('dicts/relations');
    commit('SET_RELATIONS_DICTIONARIES', r.data.data);
  },
  async postRelationsDictionary({ dispatch }, payload) {
    const r = await api.post('dicts/relations', payload);
    await dispatch('getRelationsDictionaries');
    return r.data.data;
  },
  async putRelationsDictionary({ dispatch }, data) {
    const { payload, id } = data;
    await api.put(`dicts/relations/${id}`, payload);
    await dispatch('getRelationsDictionaries');
  },
  async deleteRelationsDictionary({ dispatch }, id) {
    await api.delete(`dicts/relations/${id}`);
    await dispatch('getRelationsDictionaries');
  },
  async importDictionary({ dispatch }, data) {
    await api.post('dicts/import/data_source', data);
    await dispatch('getDictionaries');
  },
  async putLogo(store, data) {
    await api.put('/settings/logo', data, {
      noWrap: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  async putFavicon(store, data) {
    await api.put('/favicon.ico', data, {
      noWrap: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  async putThemePicture(store, data) {
    await api.put('/settings/theme_picture', data, {
      noWrap: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  async putCustomLogo(store, data) {
    const { name, formData } = data;
    await api.put(`/settings/logo/${name}`, formData, {
      noWrap: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  async getMobileMetadata({ commit }, app_kind) {
    try {
      const r = await api.get(`mobile_app/${app_kind}/latest/metadata`, {
        skipDefaultErrorHandler: true,
      });
      commit('SET_HAS_MOBILE_APP', { app_kind, mobileApp: true });
      commit('SET_MOBILE_APP_METADATA', { app_kind, metadata: r.data.data });
    } catch (e) {
      commit('SET_HAS_MOBILE_APP', { app_kind, mobileApp: false });
    }
  },
  async deleteMobileApp({ commit }, app_kind) {
    await api.delete(`mobile_app/${app_kind}`);
    commit('SET_HAS_MOBILE_APP', { app_kind, mobileApp: false });
  },
  async syncSqlite({ dispatch }) {
    await api.post('sync/sqlite', {}, { timeout: 0 });
    await dispatch('getSettings');
  },
  async syncMbtiles({ dispatch }) {
    await api.post('sync/tiles', {}, { timeout: 0 });
    await dispatch('getSettings');
  },
  async getQgisMetadata({ commit }) {
    try {
      const r = await api.get('qgis_plugins/versions?latest_only=true');
      commit('SET_HAS_QGIS', true);
      commit('SET_QGIS_METADATA', ...r.data.data);
    } catch (e) {
      commit('SET_HAS_QGIS', false);
    }
  },
  async getGeoserverMetadata({ commit }) {
    const r = await api.get('/geoserver_available');
    commit('SET_GEOSERVER', r.data.data);
  },
  async getEventsLog(store, data) {
    const { features_filter, sorting, params } = data;
    return (
      await api.post(
        '/event_log',
        {
          features_filter,
          sorting,
        },
        {
          params,
        }
      )
    ).data.data;
  },
  async getEventLog(store, id) {
    return (await api.get(`/event_log/${id}`)).data;
  },
  async getActivitiesLog(store, data) {
    const { features_filter, sorting, params } = data;
    const r = await api.post('/activity_log', { features_filter, sorting }, { params });
    return r.data.data;
  },
  async getActivitiesUniques() {
    const r = await api.get('activity_log/activity_type');
    return r.data.data;
  },
  async getMobileRegistryDevices({ commit }) {
    const r = await api.post('mobile/devices', {});
    const devices = (r.data.data?.features?.features || []).map(device => {
      const { assigned_users, device_name, paired } = device.properties || {};
      return { id: device.id, users: assigned_users, name: device_name, paired };
    });
    commit('SET_MOBILE_REGISTRY_DEVICES', arrayToObject(devices));
  },
  async getMobileRegistryDevice({ commit }, deviceId) {
    const r = await api.get(`mobile/devices/${deviceId}`);
    const { assigned_users: users, device_name: name, paired, id } = r.data.data || {};
    const device = { id, users, name, paired };
    commit('SET_MOBILE_REGISTRY_DEVICE', device);
    return device;
  },
  async addMobileRegistryDevice({ dispatch }, payload) {
    await api.post('mobile/devices/new', payload);
    await dispatch('getMobileRegistryDevices');
  },
  async deleteMobileRegistryDevice({ dispatch }, deviceId) {
    await api.delete(`mobile/devices/${deviceId}`);
    await dispatch('getMobileRegistryDevices');
  },
  async editMobileRegistryDevice({ dispatch }, data) {
    const { deviceId, payload } = data;
    await api.put(`mobile/devices/${deviceId}`, payload);
    await dispatch('getMobileRegistryDevices');
  },
  async unpairMobileRegistryDevice({ dispatch }, deviceId) {
    await api.post(`mobile/devices/${deviceId}/unpairing`);
    await dispatch('getMobileRegistryDevices');
  },
  async getPairingMobileRegistyDeviceUuid(store, deviceId) {
    const r = await api.get(`mobile/devices/${deviceId}/pairing`);
    return r.data.data;
  },
  async getPublicTokens({ commit }) {
    const r = await api.get('public_tokens');
    commit('SET_PUBLIC_TOKENS', r.data.data);
  },
  async addPublicToken({ dispatch }, payload) {
    await api.post('public_tokens', payload);
    await dispatch('getPublicTokens');
  },
  async deletePublicToken({ dispatch }, token) {
    await api.delete(`public_tokens/${token}`);
    await dispatch('getPublicTokens');
  },
  async validateAdvancedExpression(store, { dataSourceName, payload } = {}) {
    await api.post(`dataio/data_sources/${dataSourceName}/trigger`, payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
